var COMPONENT_TYPES;
(function (COMPONENT_TYPES) {
    COMPONENT_TYPES["FavoriteToggle"] = "favorite-toggle-module";
    COMPONENT_TYPES["FavoriteCollection"] = "favorite-collection-module";
    COMPONENT_TYPES["TableOfContent"] = "table-of-content-module";
    COMPONENT_TYPES["PageTypeList"] = "page-type-list-module";
    COMPONENT_TYPES["UmbracoImage"] = "umbraco-image-module";
    COMPONENT_TYPES["EventList"] = "event-list-module";
    COMPONENT_TYPES["FindEducation"] = "find-education-module";
    COMPONENT_TYPES["Search"] = "search-module";
    COMPONENT_TYPES["HeaderSearch"] = "header-search-module";
    COMPONENT_TYPES["employeeSearch"] = "employee-search-module";
    COMPONENT_TYPES["contactCard"] = "contact-card-module";
    COMPONENT_TYPES["EvuTabsList"] = "evu-tabs-list-module";
    COMPONENT_TYPES["EvuChildrenList"] = "evu-children-list-module";
    COMPONENT_TYPES["OverviewListWithFilters"] = "overview-list-with-filters-module";
    COMPONENT_TYPES["RegistrationsModal"] = "registrations-modal-module";
    COMPONENT_TYPES["EResourceList"] = "e-resource-list-module";
    COMPONENT_TYPES["HeroSearch"] = "hero-search-module";
})(COMPONENT_TYPES || (COMPONENT_TYPES = {}));
export { COMPONENT_TYPES };
